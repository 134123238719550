@import "../css/reset";
@import "sass-lib";

@media screen and(min-width:1px) and(max-width:1365px){
	header {
		&.active div.right .hamburger { top: -11px; }
	}
	div.menu-popup .menu-language {
	    position: absolute;
	    right: 50px;
	    top: 0px;
	}
}
@media screen and(min-width: 768px) and(max-width: 1023px){
	section {
		&.program {
			.inner {
    			padding: 0px 32px 0px 40px !important;
			}
			.slider {
				.slide { padding-right: 0px; }
			}
		}
		&.single-column , &.double-column {
			.share-socmed { top: -68px; }
		}
		&.about-intro { padding-bottom: 100px; }
	}
	div.teams-list.teams-right .bio .title-name {
	    margin-left: 36px;
	    max-width: 280px;
	}
	.sub-menu-mobile { position: absolute; top: -73px; left: 44%; z-index: 110; height: 30px; }
}
@media screen and(min-width:1px) and(max-width:1365px){
	section {
		&:not(.seen-on , .banner) { overflow: hidden; }
	}
}
@media screen and(min-width:1px) and(max-width:1023px){
	.video-pause-trigger { display: none; }
	div.tutors-list .name-alma .name {
	    max-width: 112px;
	    max-height: 20px;
	}
	section.seen-on {
		ul {
			position: relative;
			li { display: block; float: left; min-height: 90px; }
			&::after { content:""; display: block; clear: both; }
		}
	}
	form {
		&.basic { padding: 51px 34px 100px 34px; }
	}
	body { position: relative; 
		&.home { 
		}
	}
	span {
		&.expand { font-size: 14px; color: #cccccc; text-transform: capitalize; position: absolute; top: 0px; min-width: 190px; width: 190px; padding: 5px; background-color: #fff; 
			.acc-arrow { position: absolute; top: 8px; right: 20px; width: 11px; height: 11px; background: url('../images/material/arrow-nav-side.png'); background-size: 100% 200%; background-position: bottom center; @include rotation(180deg); @include transition(all 0.35s ease-in-out); transition-delay: 0.4s; -moz-transition-delay: 0.4s; -webkit-transition-delay: 0.4s;
				&.active {  @include rotation(0deg); }
			}
		}
	}
	a {
		&.highlight-more, &.view-all { font-size: 14px; }
		&.btn { font-size: 16px; padding: 20px; }
	}
	div {
		&.title {
			h6 { font-size: 16px; }
			h2 { font-size: 35px; }
			p { font-size: 14px; line-height: 22px;
				&.hasborder {
					padding-left: 79px;
					span.decor { width: 69px;top: 9px; }
				}
			}
			&.hasborder {
				padding-bottom: 15px;
			}
		}
		&.menu-popup {
			padding-top: 23px; overflow-x: hidden; max-width: 100%;
			.menu-search {
				text-align: center; padding-top: 75px; margin-bottom: 53px;
			}
			.menu-box {
				width: 100%; text-align: center; padding-right: 0px; 
				b { line-height: 18px; padding:26px 0px; }
				a { font-size: 35px; text-transform: uppercase; font-weight: normal; }
				ul { display: none; }
			}
			.menu-socmed { left: 0px; text-align: center; bottom: -80px; 
				li {
					margin: 0px 15px;
					span { width: 39px; height: 39px; background-size: 100% 200%; }
				}
			}
		}
		&.wrapper , &.wrapper-2 { width: 93.2%; }
		&.wrapper-fluid {
			&.hasborder { padding: 49px 18px 49px 7px; }
		}
		&.pagination {
			margin: 40px 0px;
			a.prev, a.next { top: 12px; }
		}
		&.wrapper-tutor-list {
			@include boxSizing(border-box); padding: 0px 30px;
		}
		&.tutors-list {
			.name-alma {
				left: 16px; right: 16px; padding: 11px 11px;
				.left {
					&.name { margin: 3px 0px; }
					p { font-size: 14px; margin: 0px 0px; }
				}
				.right {
					&.alma {
						min-height: 30px;
						&::before { top: 5px; right: 52px; bottom: 5px; }
					}
					img { max-height: 30px; margin: 0px 0px; }
				}
			}
			figure { padding-bottom: 8px; }
			&:nth-child(odd) {
				.triangle { right: 24px; }
			}
			.triangle { width: 30px; height: 30px; top: 14px; }
		}
		&.price-list {
			.price {
				p { font-size: 31px; margin-bottom: 6px;
					sup { font-size: 12px; position: relative; top: -7px; }
				}
				span { font-size: 12px; }
			}
			.list { 
				padding: 66px 43px; 
				table {
					width: 100%;
					tr {
						td { padding: 3px 5px; font-size: 14px; line-height: 20px; }
					}
				}
			}
		}
		&.file-download {
			.file-title { font-size: 16px; margin-bottom: 8px; }
		}
		&.testimonial-txt {
			.top {
				img { max-height: auto; max-width: 52px; }
			}
		}
		&.map { height: 620px; margin-top: 60px; }
		&.wrapper-contact { padding: 0px 30px; @include boxSizing(border-box); }
		&.career-list {
			.status-role {
				.status {
				    line-height: 35px;
				    font-size: 18px;
				}
				.role {
					font-size: 22px;
				}
			}
		}
		&.status-role {
			margin-bottom: 30px;
			.status {
				font-size: 16px;
			}
			.role {
				font-size: 28px;
			}
		}
	}
	b {
		&.title , &.title-slider {
			font-size: 30px; line-height: 38px;
		}
	}

	header {
		&.active div.right .language {
		    top: 4px;
		    right: 28px;
		}
		z-index: 200;
	}

	section {
		&.main-slider {
			.main-slides .slick-dots button { margin: 10px 0px; 
				&::before {
					width:39px;
				}
			}
			.main-slides .slick-dots .slick-active button::before {
			    width: 71px;
			}
			.main-slides { margin: 0px 0px; }
        	.basic , .video {
        		height: 100vh; max-height: 768px;
        		img { height: 100vh; max-height: 768px; max-width: 200%; }
        	}
        	.basic {
        		.caption {
        			.inner { max-width: 68%; margin: auto; }
        			h1 { font-size: 42px !important; padding: 12px 0px !important; }
        			p { font-size: 11px !important; margin:12px 0px !important; }
        		}
        	}
        	.video {
        		.caption {
        			max-width: 56%;
        			.inner { padding: 0px 57px 0px 132px; width: 100%; }
        			.play { right: auto !important; left: 135px; top: auto; bottom: -120px; transform: translateY(0%); width: 70px; height: 68px; 
        				&:hover { width: 60px; height: 59px; right: -31px; }
        			}
        			h1 { font-size: 40px; line-height: 55px; 
        				span.border { bottom: -20px; }
        			}
        		}
        	}
		}
		&.seen-on {
			.wrapper { width: 88.2%; }
			ul {
				li { width: 16.2%; padding: 5px; @include boxSizing(border-box);
					img { zoom:0.8; }
				}
			}
		}
		&.about-intro {
			div.title {
				top: 44px;
			}
		}
		&.program {
			overflow: hidden;
			.decor-2 { display: none; }
			span.big-decor { bottom: 25px; }
			.inner { padding: 0px 117px 0px 67px; }
			.caption-fixed {
				left: auto; right: 0; width: 50%;
				p {
				    font-size: 16px; line-height: 22px; margin-top: 14px;
				}
			}
			.caption-fixed {
				left: auto; right: 0px; width: 53%;
			}
			.slider {
				.slide {
				    width: auto;
				}
				button.slick-prev, button.slick-next {
				    width: 45px;
				    height: 42px;
				    &:hover {
				    	width: 35px;
				    	height: 33px;
				    }
				}
			}
		}
		&.work {
			margin-bottom: 88px;
			div.work-list {
				margin: 0px 0px; padding: 84px 0px 175px;
				.list {
					padding: 0px 1px;
					span.work {
						zoom:0.8;
					}
				}
			}
		}
		&.testimonial {
			padding-bottom: 0px; margin-bottom: 50px; padding-top: 35px;
			> span.decor , span.decor-2 { display: none; }
			span.decor-3 { right: 9px; }
			span.big-bg-decor { max-width: 570px; max-height: 459px; bottom: 36px; }
			.highlight , .wrap-list { padding-top: 0px; margin-top: 15px;}
			.highlight { padding-right: 30px; }
			.wrap-list { 
				width: 53%; padding-left: 10px;
				.list { 
					height: 150px; overflow: hidden;
					.back .inner { right: 21px; bottom: 21px; }
					.back .inner p.title-testi { font-size: 18px; text-transform: uppercase; }
					.back:hover p.title-testi { padding-bottom: 8px; margin-bottom: 8px; }
					.back .inner a.read-more { font-size: 13px; }
					img { height: 100%; width: auto; max-width: 200%; }
				}
				div.container-view-all {
				    padding: 72px 27px;
				}
			}
			.highlight {
				width: 47%;
				.highlight-titledesc {
					padding: 21px 0px 29px 19px;
					p.highlight-title { font-size: 18px; margin-bottom: 17px; text-transform: uppercase; }
					p.highlight-desc { font-size: 14px; line-height: 20px; margin-bottom: 50px; }
				}
			}
		}
		&.coverage {
			margin-bottom: 107px;
			.wrapper-decor {
				span.decor { display: none; }
			}
			.media-container {
				padding-bottom: 0px;
				.list {
					.inner {
						min-height: 235px;
						.triangle-corner { width: 36px;height: 36px;background-size: 100%; margin-bottom: 15px; }
						p.coverage-title { font-size: 13px; line-height: 18px; margin-bottom: 30px; 
							a { height: auto; max-height: 69px; }
						}
						p.coverage-content { display: none; }
						.content-wrapper {
							img { height: 17px; right: 12px; bottom: 12px; }
						}
					}
				}
				span.decor-2 { display: none; }
			}
		}
		&.join {
			margin-bottom: 293px;
			.decor , .decor-2 { display: none; }
			.join-desc {
				position: relative;width: 100%; transform: translateY(0%); top: 0px; padding: 42px 30px; margin: 0px 0px; max-width: 100%; text-align: left;
				h6 { font-size: 18px; }
				h2 { font-size: 35px; margin: 10px 0px; }
				p { font-size: 14px; float: none; max-width: 80%; }
			}
			.join-form-wrap {
				float: none; width: 100%; padding: 49px 30px; 
				.input-group { max-width: 100%; }
			}
			button { width: 100%; }
		}
		&.banner {
			.img { height: 231px; }
			.left-content { 
				width: 39.2%; height: 250px; 
				.inner {
					bottom: 23px; right: 27px;
					h1 { font-size: 23px; line-height: 32px; }
					ul.breadcrumb {
						li {
							a { font-size: 12px; }
							&::after {
							    content: '/';
							    margin-right: 2px;
							    margin-left: 7px;
							    color: #ffffff;
							}
						}
					}
				}
				&::after { bottom: -32px; }
			}
		}
		&.double-column , &.double-column-tablet {
			aside {
				float: none; width: 100%; 
				div.side-menu { position: absolute; top: 0px; left: 48%; z-index: 15;
					ul { 
						margin-top: 30px; background-color: #015b7d; padding: 10px; width: 190px; @include boxSizing(border-box);
						li {
							border: 0px solid #fff; padding: 10px;
							a { color: #fff; padding-left: 0px; font-size: 14px;
								&.active { padding-left: 0px; }
								&::before { display:none; }
							}
							ul {
								li {
									a { font-size: 14px; }
								}
							}
						}
					}
				}
			}
			article {
				margin-left: 0px;
				h3 { font-size: 20px; line-height: 26px; max-width: 100%; margin-bottom: 10px; }
			}
		}
		&.double-column , &.single-column {
			margin-top: 70px;
			.wrapper-2 { width: 88.2%; }
			> .decor { display: none; }
			div.share-socmed {
	            text-align : right; margin-top: 13px; margin-bottom: 41px; width: 100%;
	            ul {
	                li {
	                    span {
	                        &.fb-circle , &.tw-circle { width: 24px; height: 28px; background-size: 100% 200%; }
	                    }
	                }
	            }
	        }
	        aside {
	        	&.first-meeting { display: none; }
	        	.title-desc { width: 100%; max-width: 100% !important; }
	        	b { font-size: 16px; }
	        	h2 { font-size: 28px; line-height: 34px; margin: 0px 0px 15px 0px; }
	        	p { font-size: 14px; line-height: 20px; }
	        	ul {
	        		li.hasChild {
		        		span.arrow { top: 10px; }
		        		> ul { 
		        			margin-top: 0px; padding-left: 18px; 
		        			li {
		        				&:first-child { margin-top:0px; }
		        				a.active { font-weight: bold; color: #fff; }
		        			}
		        		}
		        	}
		        	&.socmed-contact {
		        		li {
		        			span {
		        				&.fb-blue , &.yt-blue , &.ig-blue , &.li-blue { width: 24px; height: 24px; background-size: 100%; margin-right: 20px; }
		        			}
		        		}
		        	} 
	        	}
	        	.connect {
	        		margin-bottom: 40px;
	        		b { display: block; } 
	        		ul {
	        			li {
	        				padding: 18px 0px 0px 0px;
	        			}
	        		}
	        	}
	        	form { max-width: 100%; margin-top: 30px; 
	        		div.input-group button {
					    padding: 22px;
					}
	        	}
	        }
	        article {
            	p , b , ul li , ol li { font-size: 14px; line-height: 22px; margin: 12px 0px; }
            	h3 {
            		font-size: 20px;
            		&.news { font-size: 25px; 
            			b { font-size: 25px; }
            		}
            	}
            	.title-desc {
					 p { font-size: 16px; }
					 b { font-size: 18px; }
				}
	        }
		}
		&.single-column {

		}
		.slide-about-history {
		    h1 { margin-bottom: 0px !important; }
		}
		.slide-news-detail {
			.item {
				.content {
					.top {
					   display: none;
					}
					.content-desc { 
						margin-top: 50px; 
						p.title { font-size: 20px; max-width: 75%; margin-bottom: 45px; }
					}
				}
			}
			button {
				&.slick-prev { bottom: 77px; }
				&.slick-next { bottom: 35px; }
			}
		}
		.wrap-slider-news .white-bg { width: 52px; height: 122px; left: 43.5%; bottom: 0px; }
	}

	footer {
		.top {
			img { margin: 0 auto; }
		}
		.col-l-four {
			display: none;
			&:nth-child(5) {
			    padding-left:0px; width: 50%; display: block; margin: 0 auto; text-align: center; float: none;
			    &::after { content:""; clear: both; }
			}
		}
		ul.socmed {
		    bottom: -68px;
		    left: 0px;
		    display: inline-block;
		    text-align: center;
		}
		.seen-on {
			margin-top: 118px;
			ul {
				li {
					img { zoom:0.7; margin: 0 auto; }
				}
			}
		}
		.rights { color: #928989; }
	}

}

@media screen and(min-width:1px) and(max-width:1365px){
	header {
		div.left {
			.logo img { max-height: 57px; margin-top: 3px;  }
			ul {
				bottom: 23px; display: none;
			}
		}
		div.right {
			margin-top: 5px;
			.join { font-size: 13px; padding: 9px 5px; }
			.hamburger { top: -4px; margin-left: 20px; }
			.language {
				right: 105px; top: 12px;
				ul { min-width: 120px; }
			} 
		}
	}
	&.active {
		div.right {
			.language {
				right: 30px; top: 2px !important;
			} 
		}
	}
}

@media screen and(min-width:767px) and(max-width:1023px){
	div {
		&.menu-popup {
			.wrap-menu { margin-bottom: 180px; }
		}
	}
}

@media screen and(min-width:1024px) and(max-width:1365px){
	form {
		&.basic { padding: 49px 32px 90px 32px; }
	}
	div {
		&.title {
			h6 { font-size: 20px; }
			h2 { font-size: 50px; }
			p { font-size: 16px; }
		}
		&.wrapper , &.wrapper-2 { width: 88.2%; }
		&.wrapper-fluid {
			&.hasborder { padding: 49px 32px; }
		}
		&.teams-list {
			margin:0px 0px;
			&.teams-right {
				.bio {
					.number { top:45px; right: 60px; font-size: 40px; }
					.image {
						width: 200px; height: 320px; 
						img { max-width: 264px; }
					}
					.title-name {
						margin-left: 35px; max-width: 260px;
					}
				}
			}
			.bio {
				.number { left: 70px; font-size: 40px; }
				.image {
					width: 200px; height: 320px; 
					img { max-width: 264px; }
				}       
				.title-name {
					margin-left: 300px; max-width: 290px;
					p.title { font-size: 14px; margin: 0px 0px; }
					b.name { font-size: 16px; margin: 0px 0px; }
				}
			}
		}
		&.tutors-list {
			.name-alma {
				left: 16px; right: 16px; padding: 11px 11px;
				.left {
					&.name { margin: 3px 0px; max-width: 110px; max-height: 25px; }
					p { font-size: 14px; margin: 0px 0px; }
				}
				.right {
					&.alma {
						min-height: 30px;
						&::before { top: 5px; right: 52px; bottom: 5px; }
					}
					img { max-height: 30px; margin: 0px 0px; }
				}
			}
			figure { padding-bottom: 8px; }
			&:nth-child(odd) {
				.triangle { right: 24px; }
			}
			.triangle { width: 30px; height: 30px; top: 14px; }
		}
		&.career-list {
			width: 46%; height: 326px;
			.status-role {
				.status { font-size: 18px; line-height: 26px; }
				.role { font-size: 22px; }
			}
			.details { padding: 21px; }
		}
		&.testimonial-txt {
			padding-bottom: 15px;
			.top {
				img {
				    max-height: 57px; max-width: 57px;
				}
			}
		}
		&.news-list {
			h3 { margin-bottom: 0px !important; }
			.content { margin-top: 10px; }
		}
		&.map {
	        height: 587px;
	    }
	}
	b {
		&.title, &.title-slider {
		    font-size: 40px; line-height: 45px;
		    &.hasborder {
		    	&::after { bottom:-5px; }
		    }
		}
	}
	section {
		position: relative;
		&.main-slider {
			.main-slides .slick-dots button { margin: 10px 0px; 
				&::before {
					width:39px;
				}
			}
			.main-slides .slick-dots .slick-active button::before {
			    width: 71px;
			}
			.main-slides { margin: 0px 0px; }
        	.basic , .video {
        		height: 100vh;
        		img { min-height: 100vh; min-width: 100%; max-width: 200%; }
        	}
        	.basic {
        		.caption {
        			h1 { font-size: 48px; }
        			p { font-size: 14.7px; margin:18px 0px; }
        		}
        	}
        	.video {
        		.caption {
        			max-width: 56%;
        			.inner { padding: 0px 57px 0px 132px; }
        			.play { right: -36px; width: 70px; height: 68px; 
        				&:hover { width: 60px; height: 59px; right: -31px; }
        			}
        			h1 { font-size: 40px; line-height: 55px; 
        				span.border { bottom: -20px; }
        			}
        		}
        	}
		}
		&.seen-on {
			ul {
				li { width: 16.2%; 
					img { zoom:0.9; }
				}
			}
		}
		&.double-column , &.single-column {
			article {
				h3 { font-size: 20px; line-height: 28px; }
            	p , b , ul li , ol li { font-size: 16px; line-height: 26px; margin: 17px 0px; }
				.title-desc { margin-bottom: 40px; }
			}
		}
		&.about-intro {
			 div.title { top: 89px; }
		}
		&.program {
			.decor-2 { display: none; }
			.inner { padding: 0px 117px 0px 67px; }
			.caption-fixed {
				left: auto; right: 0; width: 50%;
				p {
				    font-size: 16px; line-height: 22px;
				}
			}
			.slider {
				.slide {
				    width: auto;
				    .caption { display: none; }
				}
			}
		}
		&.work {
			margin-bottom: 0px;
			div.work-list {
				padding: 65px 0px 140px;

				.list span.work { zoom: 0.8; }
				.list {
					span.tooltip-text { left:15%; }
					&:last-child {
						span.tooltip-text { left:-79%; }
						span.tooltip-text::before { right: 30px; left: auto; }
					}
				}
				span.decor {
				    height: 90px;
				}
			}
		}
		&.testimonial {
			padding-bottom: 0px; margin-bottom: 50px;
			> span.decor , span.decor-2 { display: none; }
			span.big-bg-decor { max-width: 770px; max-height: 630px; }
			.highlight , .wrap-list { padding-top: 0px; margin-top: 15px;}
			.highlight { padding-right: 30px; }
			.wrap-list { 
				width: 51%; padding-left: 30px;
				.list { 
					height: 200px; overflow: hidden;
					.back .inner p.title-testi { font-size: 20px; }
					.back:hover p.title-testi { padding-bottom: 8px; margin-bottom: 8px; }
					.back .inner a.read-more { font-size: 14px; }
					img { height: 100%; width: auto; max-width: 200%; }
				}
			}
			.highlight {
				width: 49%;
				.highlight-titledesc {
					padding: 29px 0px 29px 42px;
					p.highlight-title { font-size: 20px; margin-bottom: 17px; }
					p.highlight-desc { font-size: 16px; margin-bottom: 59px; }
				}
			}
		}
		&.coverage {
			margin-bottom: 50px;
			.wrapper-decor {
				span.decor { display: none; }
			}
			.media-container {
				padding-bottom: 0px;
				.list {
					.inner {
						min-height: 390px;
						.triangle-corner { width: 47px;height: 48px;background-size: 100%; margin-bottom: 15px; }
						p.coverage-title { font-size: 16px; line-height: 22px; margin-bottom: 30px; }
						p.coverage-content { max-height: 95px; overflow: hidden; font-size: 14px; line-height: 20px; }
						.content-wrapper {
							img { height: 17px; }
						}
					}
				}
				span.decor-2 { display: none; }
			}
		}
		&.join {
			margin-bottom: 157px;
			.decor , .decor-2 { display: none; }
			.join-desc {
				padding-right: 28px;
				h6 { font-size: 20px; margin-bottom: 5px; }
				h2 { font-size: 50px; margin-bottom: 10px; }
				p { max-width: 90%; }
			}
			.join-form-wrap {
				padding: 47px 31px;
				.input-group { max-width: 100%; 
				}
			}
		}
		&.banner {
			.left-content { width: 37%; height: 317px; 
				.inner {
					right: 29px; bottom: 29px; max-width: 80%;
					h1 { font-size: 30px; line-height: 45px; }
				}
			}
			.img { height: 286px; margin-left: 37%; }
		}
		&.double-column , &.single-column {
			> .decor { display: none; }
			&.has-socmed {
				margin-top: 30px;
			}
			div.share-socmed, div.share-socmed {
			    margin-bottom: 20px;
			}
			aside {
				b { font-size: 16px; }
				h2 { font-size: 28px; line-height: 32px; }
				form {
					margin-top: 28px; max-width: 249px;
					button { padding: 20px; }
				}
				div.title-desc {
					max-width: 240px;

				}
				ul.socmed-contact {
	                display: inline-block; margin-bottom: 60px;
	                li {
	                    display: inline-block; border: none; padding: 22px 0px 0px 0px;
	                    span {
	                        &.fb-blue , &.yt-blue , &.ig-blue , &.li-blue { width: 23px; height: 24px; background-size: 100% 200%; margin-right: 10px;
	                            &:hover { background-position: bottom center; }
	                        }
	                    }
	                }
	            }
				ul.hasborder {
					li {
						padding: 20px 0px 0px 0px;
						a { font-size: 13px; 
							&.active { font-size: 18px; 
								&::before {
									width:69px;
								}
							}
							&::before {
							    width: 39px;
							}
						}
					}
				}
			}
			article {
				.title-desc {
					p { font-size: 16px; }
					b { font-size: 18px; }
				}
			}
		}
		&.double-column {
			aside {
				width: 315px;
				ul {
					&.side {
						width: 228px;
						li {  }
					}
				}
			}
			article {
				margin-left: 316px;
			}
		}
		&.not-found {
			background-position: center center;
			.content {
				width: 54%;
				h1 , h3 { padding-right: 39px; }
				h1 { font-size: 80px; }
				h3 { font-size: 30px; line-height: 45px; }
				.inner {
					&::after { max-width:190px; }
				}
			}
			a.highlight-more { font-size: 18px; 
				span.arrow-right { top: -3px; }
			}
		}

		.slide-about-teams , .slide-about-intl {
			.video {
				.caption {
					.inner { padding: 0px 100px 0px 30%; }
					b { font-size: 28px; line-height: 32px; 
						&::after { width:130px; }
					}
					.play { right: -36px; width: 70px; height: 68px; 
        				&:hover { width: 60px; height: 59px; right: -31px; }
        			}
				}
			}
		}
		.slide-about-teams { margin-bottom: 50px; }
	}
	footer {
		.col-l-four {
			width: 37%;
			&:nth-child(n+4) { width: 26%; }
			&:nth-child(n+5) { display: none; }
		}
		.seen-on {
			ul {
				li {
					img { zoom:0.83; }
				}
			}
		}
	}
}